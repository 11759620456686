import React from 'react';
import { Link } from 'react-router-dom';

const Note = () => (
    <div className="section">
        <div className="row">
            <div className="col s12 m6 center-align offset-m3">
                <div className="card hoverable">
                    <div className="card-content black-text z-depth-1">
                        <h6 className="center-align">
                            The following projects were selected at random... visit <Link to="/projects">Projects
                            Page</Link> for all my projects
                        </h6>
                        <h6 className="center-align">Also check out my <a href="http://github.com/afranco07">GitHub</a> for
                            all the source code
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Note;
