import React from 'react';

const EDUCATION = [
    {
        id: 1,
        companyName: "Queens College, City University of New York",
        title: null,
        subtitle: null,
        bulletPoints: [
            <h6 key={0}>Bachelors of Science, Computer Science May 2018</h6>
        ]
    },
    {
        id: 0,
        companyName: "Relevant Courses",
        title: null,
        subtitle: null,
        bulletPoints: [
            <ul key={0}>
                <li>Data Structures</li>
                <li>Algorithms</li>
                <li>Software Design</li>
                <li>Web Development</li>
                <li>Assembly Language</li>
                <li>Computer Organization</li>
                <li>Operating Systems</li>
                <li>Databases</li>
                <li>Theory of Computation</li>
            </ul>
        ]
    }
];

export default EDUCATION;