import React, { Component } from 'react';
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

class Modal extends Component {

    componentDidMount() {
        M.Modal.init(this.Modal);
        // let instance = M.Sidenav.getInstance(this.Sidenav);
        // instance.open();
    }

    render() {
        return (
            <div id={ this.props.modalTitle } className="modal" ref={Modal => this.Modal = Modal}>
                <div className="modal-content">
                    <h4>{ this.props.title }</h4>
                    <img className="responsive-img" src={ this.props.gif } alt={this.props.title}/>
                </div>
                <div className="modal-footer">
                    <button className="modal-action modal-close waves-effect waves-green btn-flat">Close</button>
                </div>
            </div>
        );
    }
}

export default Modal;
