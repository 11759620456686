import React, { Component } from 'react';
import motherboardImage from './motherboard-repair.jpg';

class AboutMe extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12">
                        <h4 className="center-align">Under Construction!</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 center-align">
                        <img className="responsive-img" src={motherboardImage} alt="Construction Workers" />
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutMe;
