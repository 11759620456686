import React, { Component } from 'react';
import GOLANG_PROJECTS from '../data/projects/golangprojects.js';
import IOS_PROJECTS from '../data/projects/iosprojects.js';
import JAVA_PROJECTS from '../data/projects/javaprojects.js';
import PYTHON_PROJECTS from '../data/projects/pythonprojects.js';
import WEB_PROJECTS from '../data/projects/webapps.js';
import MACOS_PROJECTS from '../data/projects/macosprojects.js';
import UNITY_PROJECTS from '../data/projects/unityprojects.js';
import Card from '../card/card.js';
import Modal from '../modal/modal.js';


class RandomProjects extends Component {
    state = {
        projects: [...GOLANG_PROJECTS, ...IOS_PROJECTS, ...JAVA_PROJECTS, ...PYTHON_PROJECTS, ...WEB_PROJECTS, ...MACOS_PROJECTS, ...UNITY_PROJECTS],
        randomProjects: [],
        modals: []
    }

    componentDidMount() {
        this.pickRandomProjects();
    }

    pickRandomProjects() {
        let randomProjects = [];
        let modals = [];
        let pickedNumbers = new Set();
        for (let i = 0; i < 3; i++) {
            let num = Math.floor(Math.random() * this.state.projects.length);
            while (pickedNumbers.has(num)) num = Math.floor(Math.random() * this.state.projects.length);
            pickedNumbers.add(num);
            let project = this.state.projects[num];
            randomProjects.push(
                <Card imgSource={project.imgSource}
                    title={project.title}
                    info={project.info}
                    github={project.github}
                    modalTitle={project.modalTitle}
                    website={project.website}
                    key={num}
                />
            );
            modals.push(
                <Modal title={project.title}
                    gif={project.gif}
                    modalTitle={project.modalTitle}
                    key={num}
                />
            );
        }
        this.setState({ randomProjects, modals });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    { this.state.randomProjects }
                    { this.state.modals }
                </div>
            </div>
        );
    }
}

export default RandomProjects;
