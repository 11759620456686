import { WEB } from './languages.js';

const WEB_PROJECTS = [
    {
        id: 10,
        title: "Payment App",
        modalTitle: "Payment App",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/payment_app_screenshot.png?raw=true",
        info: "An web application built with Python + Django that functions similar to apps like Venmo or Paypal. Payments are processed using the Stripe API. Includes logging in with Google oauth. Tests are included along with 100% code coverage with those tests.",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/payment_app.gif?raw=true",
        github: "https://gitlab.com/afranco07/django-challenge",
        website: "https://paymentapp-python.herokuapp.com/payment/"
    }, {
        id: 9,
        title: "IGTP Contractors LLC",
        modalTitle: "IGTP Contractors LLC",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/igtpScreenshot.PNG?raw=true",
        info: "I website/landing page I created for the company IGTP Contractors LLC. This website was made with ReactJS and Bootstrap",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/igtpcontractors.gif?raw=true",
        github: "https://github.com/afranco07/IGTP",
        website: "https://www.igtpcontractors.com/"
    }, {
        id: 8,
        title: "Stocks App",
        modalTitle: "Stocks App",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/stocksappScreenshot.PNG?raw=true",
        info: "A web application that allows you to buy and view stocks (not with real money, each user starts with $5000). Includes the ability to create new users and each user has their own set of stocks purchased. Uses ReactJS on the front end and Go on the backend. Users are authenticated with api JWTs",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/stocksapp.gif?raw=true",
        github: "https://github.com/afranco07/stock-market",
        website: "https://stock-market-fullstack.herokuapp.com/"
    }, {
        id: 7,
        title: "Yelp API",
        modalTitle: "Yelp API",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/yelpAPIscreenshot.PNG?raw=true",
        info: "API endpoint to return Yelp search results using current location and radius. Made with Python Flask and Heroku.",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/yelpAPIscreenshot.PNG?raw=true",
        github: "https://github.com/afranco07/yelpSearch",
        website: "https://yelpsearch.herokuapp.com/"
    }, {
        id: 6,
        title: "Snaq Time",
        modalTitle: "Snaq Time",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/snaqTimeScreenshot.PNG?raw=true",
        info: "Progressive Web Application made using ReactJS. The Tinder for food. Swipe to find a restaurant for you! Pictures from the restaurants around you are presented in a Tinder-like style card. Images are provided using the Yelp API. Supports touch-based gestures.",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/snaqtime.gif?raw=true",
        github: "https://github.com/afranco07/SnaqTime",
        website: "https://snaqtime.herokuapp.com/"
    }, {
        id: 5,
        title: "Ionic Todo App",
        modalTitle: "Ionic Todo App",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/ionicTodoScreenshot.png?raw=true",
        info: "A todo list app that was made using Ionic framework for crossplatform mobile development",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/ionicTodoGif.gif?raw=true",
        github: "https://github.com/afranco07/ionic-todo-app",
        website: null
    }, {
        id: 4,
        title: "CCNY Stats Backend",
        modalTitle: "CCNY Stats Backend",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/ccnyBackendScreenshot.png?raw=true",
        info: "API for getting player data. Uses Node.js, Express, PostgreSQL, and Sequelize for the ORM.",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/ccnyBackendGif.gif?raw=true",
        github: "https://github.com/afranco07/ccny-stats-backend",
        website: "https://ccnybackend.herokuapp.com/"
    }, {
        id: 3,
        title: "CCNY Stats",
        modalTitle: "CCNY Stats",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/ccnyStatsScreenshot.png?raw=true",
        info: "Frontend for ccny stats using React.js. Displays player stats and allows for updating player stats from backend. Backend is 'ccny-stats-backend'",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/ccnyStatsGif.gif?raw=true",
        github: "https://github.com/afranco07/ccny-stats-frontend",
        website: "https://ccnystats.herokuapp.com/"
    }, {
        id: 2,
        title: "WePark",
        modalTitle: "WePark",
        language: WEB,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/weParkScreenshot.png?raw=true",
        info: "Imagine a time where you are desperately finding yourself a parking lot at a street, and I guess you don't like that. WePark is here to save your time, with WePark, you never worry about find parking anymore.",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/weParkGif.gif?raw=true",
        github: "https://github.com/wenn009/WePark",
        website: "https://wepark.herokuapp.com/"
    }, {
        id: 1,
        title: "Code Player",
        modalTitle: "codePlayer",
        language: WEB,
        imgSource: "https://raw.githubusercontent.com/afranco07/codePlayer/master/codeplayerCard.PNG",
        info: "Website site for typing in HTML, CSS, Javascript and seeing the results. Similar to sites like CodePen, html-online, etc.",
        gif: "https://raw.githubusercontent.com/afranco07/codePlayer/master/codePlayerDemo.gif",
        github: "https://github.com/afranco07/codePlayer",
        website: "http://venus.cs.qc.cuny.edu/~fral3186/codePlayer.html"
    }, {
        id: 0,
        title: "Frijay",
        modalTitle: "frijayApp",
        language: WEB,
        imgSource: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/shabattable.png",
        info: "Website site for people can set up or find other people/familys to join their friday dinner. Built using Bootstrap and Django framework.",
        gif: "https://raw.githubusercontent.com/afranco07/shabbatTable/master/frijayDemo.gif",
        github: "https://github.com/afranco07/shabbatTable",
        website: "https://shabbattable.herokuapp.com/"
    }
];

export default WEB_PROJECTS;