import { PYTHON } from './languages.js';

const PYTHON_PROJECTS = [
    {
        id: 1,
        title: "Address Book",
        modalTitle: "addressBook",
        language: PYTHON,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/addressbookscreen.png?raw=true",
        info: "A desktop application that stroes the addresses of users in a database. Written in Python and using a MySQL database. GUI is made with TKinter library included with Python. This application was originally intended for use in a grocery store. This application would help keep track of people who would order groceries online by entering the total amount of the purchase and exporting to an .CSV file for book keeping purposes. The exported file is called customer.csv. The address is saved for customers who regularly purchase their groceries at this grocery store.",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/addressBookDemo.gif?raw=true",
        github: "https://github.com/afranco07/AddressBook",
        website: null
    }, {
        id: 0,
        title: "Python Multithread",
        modalTitle: "pythonMultithread",
        language: PYTHON,
        imgSource: "https://raw.githubusercontent.com/afranco07/PythonMultiThread/master/pythonMulti.png",
        info: "This project is an example of multithreading in python. Simulates students rushing into a class room and trying to occupy the seats inside the class room. There is a student thread and instructor thread. They share the classroom object in which controls their synchronization. Similar to my java multithread project.",
        gif: "https://github.com/afranco07/PythonMultiThread/blob/master/python_multi_gif.gif?raw=true",
        github: "https://github.com/afranco07/PythonMultiThread",
        website: null
    }
];

export default PYTHON_PROJECTS;