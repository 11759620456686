import React, { Component } from 'react';
import GOLANG_PROJECTS from '../data/projects/golangprojects.js';
import WEB_PROJECTS from '../data/projects/webapps.js';
import JAVA_PROJECTS from '../data/projects/javaprojects.js';
import PYTHON_PROJECTS from '../data/projects/pythonprojects.js';
import IOS_PROJECTS from '../data/projects/iosprojects.js';
import MACOS_PROJECTS from '../data/projects/macosprojects.js';
import UNITY_PROJECTS from '../data/projects/unityprojects.js';
import Card from '../card/card.js';
import Modal from '../modal/modal.js';
import ProjectSection from './projectSection.js';
import { GOLANG, I_OS, JAVA, MAC_OS, PYTHON, WEB, UNITY } from '../data/projects/languages.js';

const ALL_PROJECTS = [...GOLANG_PROJECTS, ...WEB_PROJECTS, ...JAVA_PROJECTS, ...PYTHON_PROJECTS, ...IOS_PROJECTS, ...MACOS_PROJECTS, ...UNITY_PROJECTS];

class Projects extends Component {

    state = {
        golangProjects: [],
        webApps: [],
        javaProjects: [],
        pythonProjects: [],
        iosProjects: [],
        macosProjects: [],
        unityProjects: [],
        modals: []
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.mapProjects();
        this.mapProjectsToModals();
    }

    mapProjects() {
        let golangProjects = [], webApps = [], javaProjects = [], pythonProjects = [], iosProjects = [], macosProjects = [], unityProjects = [];

        ALL_PROJECTS.forEach(project => {
            let card = (
                <Card imgSource={project.imgSource}
                    title={project.title}
                    info={project.info}
                    github={project.github}
                    modalTitle={project.modalTitle}
                    website={project.website}
                    key={project.id}
                />
            );
            switch (project.language) {
                case WEB:
                    webApps.push(card);
                    break;
                case I_OS:
                    iosProjects.push(card);
                    break;
                case GOLANG:
                    golangProjects.push(card);
                    break;
                case JAVA:
                    javaProjects.push(card);
                    break;
                case MAC_OS:
                    macosProjects.push(card);
                    break;
                case PYTHON:
                    pythonProjects.push(card);
                    break;
                case UNITY:
                    unityProjects.push(card);
                    break;
                default:
                    break;
            }
        });

        this.setState({ golangProjects, webApps, javaProjects, pythonProjects, iosProjects, macosProjects, unityProjects })
    }

    mapProjectsToModals() {
        let key = 0;
        let modals = ALL_PROJECTS.map(project => {
            return (
                <Modal title={project.title}
                    gif={project.gif}
                    modalTitle={project.modalTitle}
                    key={key++}
                />
            );
        });
        this.setState({ modals })
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <ProjectSection sectionTitle="Web Applications" sectionData={this.state.webApps} />
                    <ProjectSection sectionTitle="iOS Projects" sectionData={this.state.iosProjects} />
                    <ProjectSection sectionTitle="Python Projects" sectionData={this.state.pythonProjects} />
                    <ProjectSection sectionTitle="Java Projects" sectionData={this.state.javaProjects} />
                    <ProjectSection sectionTitle="Golang Projects" sectionData={this.state.golangProjects} />
                    <ProjectSection sectionTitle="MacOS Projects" sectionData={this.state.macosProjects} />
                    <ProjectSection sectionTitle="Unity Projects" sectionData={this.state.unityProjects} />
                    {this.state.modals}
                </div>
            </React.Fragment>
        );
    }
}

export default Projects;
