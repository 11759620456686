import React, { Component } from 'react';
import M from "materialize-css";
import packageJson from '../../package.json';

class Footer extends Component {

    componentDidMount() {
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems);
    }

    render() {
        return (
            <footer className="page-footer grey darken-3">
                <div className="container">
                    <div className="row">
                        <div className="col l6 s12">
                            <h5 className="white-text">Points of Contact</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s4 center-align">
                            <a href="https://github.com/afranco07" className="tooltipped" data-position="top" data-delay="50" data-tooltip="GitHub Profile" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-github-square fa-5x" ref={Tooltip => this.Tooltip = Tooltip}></i>
                            </a>
                        </div>
                        <div className="col s4 center-align">
                            <a href="https://linkedin.com/in/afranco07" className="tooltipped" data-position="top" data-delay="50" data-tooltip="LinkedIn Profile" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin fa-5x" ref={Tooltip => this.Tooltip = Tooltip}></i>
                            </a>
                        </div>
                        <div className="col s4 center-align">
                            <a href="#!" className="tooltipped" data-position="top" data-delay="50" data-tooltip="E-Mail Me" target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-envelope fa-5x" ref={Tooltip => this.Tooltip = Tooltip}></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container">
                        © 2017 - 2023 Copyright<br />
                        <span style={{ fontSize: "0.7em" }}>v{packageJson.version}</span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
