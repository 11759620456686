import React from 'react';

const Card = props => (
    <div className="col s12 m6 l4">
        <div className="card small hoverable sticky-action">
            <div className="card-image waves-effect waves-block waves-light">
                <img className="activator" src={props.imgSource} alt={props.title} />
            </div>
            <div className="card-content">
                <span className="card-title activator grey-text text-darken-4">{props.title}<i className="material-icons right">more_vert</i></span>
            </div>
            <div className="card-reveal">
                <span className="card-title grey-text text-darken-4">{props.title}<i className="material-icons right">close</i></span>
                <p>{props.info}</p>
            </div>
            <div className="card-action">
                <a href={props.github} className="blue-text blue-darken-4" target="_blank" rel="noopener noreferrer">GitHub</a>
                <a className="modal-trigger blue-text blue-darken-4" href={`#${props.modalTitle}`}>.GIF</a>
                {props.website ? <a href={props.website} className="blue-text blue-darken-4" target="_blank" rel="noopener noreferrer">Website</a> : null}
            </div>
        </div>
    </div>
);

export default Card;
