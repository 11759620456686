import React, { Component } from 'react';
import Tabs from '../tabs/tabs.js';
import Note from '../paper/note.js';
import RandomProjects from '../projects/randomProjects.js';

class Home extends Component {
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Tabs/>
                <div className="divider black"></div>
                <Note/>
                <RandomProjects/>
            </React.Fragment>
        );
    }
}

export default Home;
