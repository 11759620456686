import React, { Component } from 'react';
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import Paper from '../paper/paper.js';
import EXPERIENCE from '../data/experience.js';
import LANGUAGETECH from '../data/languagestech.js';
import EDUCATION from '../data/education.js';

class Tabs extends Component {

    state = {
        experience: [],
        language: [],
        education: []
    }

    componentDidMount() {
        M.Tabs.init(this.Tabs);
        // let instance = M.Sidenav.getInstance(this.Sidenav);
        // instance.open();
        this.mapExperienceToPaper();
    }

    mapExperienceToPaper() {
        let newExp = EXPERIENCE.map(experience => {
            return <Paper companyName={experience.companyName}
                title={experience.title}
                subtitle={experience.subtitle}
                bulletPoints={experience.bulletPoints}
                key={experience.id}
            />
        });

        let newLangs = LANGUAGETECH.map(language => {
            return <Paper companyName={language.companyName}
                bulletPoints={language.bulletPoints}
                key={language.id}
            />
        });

        let newEducation = EDUCATION.map(education => {
            return <Paper companyName={education.companyName}
                bulletPoints={education.bulletPoints}
                key={education.id}
            />
        });
        this.setState({ experience: newExp, language: newLangs, education: newEducation });
    }

    render() {
        return (
            <React.Fragment>
                <ul className="tabs tabs-fixed-width white lighten-2 z-depth-1" ref={Tabs => this.Tabs = Tabs}>
                    <li className="tab col s3">
                        <a className="active blue-text text-darken-4" href="#experience">
                            <span className="hide-on-small-only">Experience</span>
                            <i className="fas fa-briefcase hide-on-med-and-up"></i>
                        </a>
                    </li>
                    <li className="tab col s3">
                        <a className="blue-text text-darken-4" href="#languages">
                            <span className="hide-on-small-only">Languages &amp; Technologies</span>
                            <i className="fas fa-tools hide-on-med-and-up"></i>
                        </a>
                    </li>
                    <li className="tab col s3">
                        <a className="blue-text text-darken-4" href="#education">
                            <span className="hide-on-small-only">Education</span>
                            <i className="fas fa-graduation-cap hide-on-med-and-up"></i>
                        </a>
                    </li>
                </ul>
                <div className="container">
                    <div id="experience" className="col s12 center-align">
                        {this.state.experience}
                    </div>
                    <div id="languages" className="col s12 center-align">
                        {this.state.language}
                    </div>
                    <div id="education" className="col s12 center-align">
                        {this.state.education}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Tabs;
