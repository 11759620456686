import React, { Component } from 'react';
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import { Link } from 'react-router-dom';

class NavBar extends Component {

    componentDidMount() {
        M.Sidenav.init(this.Sidenav);
        // let instance = M.Sidenav.getInstance(this.Sidenav);
        // instance.open();
    }

    render() {
        return (
            <React.Fragment>
                <nav className="grey darken-3" style={{position: "fixed", zIndex: 6}}>
                    <div className="nav-wrapper">
                        <Link to="/" className="brand-logo">Alberto <span className="hide-on-small-only">Franco</span></Link>
                        <a href="#!" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                        <ul className="right hide-on-med-and-down">
                            <li><Link to="/"><i className="material-icons left">place</i>Home</Link></li>
                            <li><Link to="/projects"><i className="tiny material-icons left">folder</i>Projects</Link></li>
                            <li><Link to="/about"><i className="tiny material-icons left">contact_mail</i>About Me</Link></li>
                        </ul>
                    </div>
                </nav>

                <ul className="sidenav" id="mobile-demo" ref={Sidenav => this.Sidenav = Sidenav}>
                    <li><Link to="/" className="sidenav-close"><i className="tiny material-icons left">place</i>Home</Link></li>
                    <li><Link to="/projects" className="sidenav-close"><i className="tiny material-icons left">folder</i>Projects</Link></li>
                    <li><Link to="/about" className="sidenav-close"><i className="tiny material-icons left">contact_mail</i>About Me</Link></li>
                </ul>
            </React.Fragment>
        );
    }
}

export default NavBar;
