import { MAC_OS } from './languages.js';

const MACOS_PROJECTS = [
    {
        id: 0,
        title: "File Browser Mac",
        modalTitle: "File Broswer Mac",
        language: MAC_OS,
        imgSource: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/macapp_screenshot.png",
        info: "MacOS application that list the files within the directory you input inside the textbox. Requires the Golang File Lister (Under Golang Projects Section) in order for this application to work.",
        gif: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/macapp_demo.gif",
        github: "https://github.com/afranco07/FileBrowserMac",
        website: null
    }
];

export default MACOS_PROJECTS;