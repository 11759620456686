
import { UNITY } from './languages.js';

const UNITY_PROJECTS = [
    {
        id: 0,
        title: "First Unity Project",
        modalTitle: "First Unity Project",
        language: UNITY,
        imgSource: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/unityscrenshot.png",
        info: "My first project created using Unity. I mostly used this to get familiar with Unity and its interface.",
        gif: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/firstunity.gif",
        github: "https://github.com/afranco07/first-unity-project",
        website: null
    }
];

export default UNITY_PROJECTS;