import { I_OS } from './languages.js';

const IOS_PROJECTS = [
    {
        id: 3,
        title: "BullsEye",
        modalTitle: "BullsEye",
        language: I_OS,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/bullsEyeScreenshot.png?raw=true",
        info: "An iOS game where you try to use the slider to match the value shown!",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/bullsEyeGif.gif?raw=true",
        github: "https://github.com/afranco07/BullsEye",
        website: null
    }, {
        id: 2,
        title: "Dicee",
        modalTitle: "Dicee",
        language: I_OS,
        imgSource: "https://github.com/afranco07/gifImageStorage/blob/master/diceeScreenShot.png?raw=true",
        info: "iOS app that simulates random dice rolls",
        gif: "https://github.com/afranco07/gifImageStorage/blob/master/diceeGif.gif?raw=true",
        github: "https://github.com/afranco07/Dicee",
        website: null
    }, {
        id: 1,
        title: "iOS Movie Viewer",
        modalTitle: "iOSMovieViewer",
        language: I_OS,
        imgSource: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/movieviewer.png",
        info: "iOS app that calculates the tip percentage based on 3 different options. Enter the bill amount and the percent for tip, total and total + tip is displayed.",
        gif: "https://raw.githubusercontent.com/afranco07/movieViewer/master/iosDemo.gif",
        github: "https://github.com/afranco07/movieViewer",
        website: null
    }, {
        id: 0,
        title: "iOS Tip Calculator",
        modalTitle: "iOSTipCalc",
        language: I_OS,
        imgSource: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/tippy.png",
        info: "iOS app that calculates the tip percentage based on 3 different options. Enter the bill amount and the percent for tip, total and total + tip is displayed.",
        gif: "https://raw.githubusercontent.com/afranco07/tippy/master/iosDemo.gif",
        github: "https://github.com/afranco07/tippy",
        website: null
    }
];

export default IOS_PROJECTS;