import { GOLANG } from './languages.js';

const GOLANG_PROJECTS = [
    {
        id: 0,
        title: "File Lister",
        modalTitle: "File Lister",
        language: GOLANG,
        imgSource: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/golang_file_lister_screenshot.png",
        info: "Golang utility that lists all the files in the directory you choose. Starts a TCP server that allows you to send the directory as a message, and responds with the the files within the directory.",
        gif: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/golang_file_lister_demo.gif",
        github: "https://github.com/afranco07/FileLister",
        website: null
    }
];

export default GOLANG_PROJECTS;