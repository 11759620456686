import React from 'react';

const ProjectSection = (props) => (
    <React.Fragment>
        <div className="col s12">
            <h4 className="center-align projectHeading">{props.sectionTitle}</h4>
        </div>
        <div className="row">
            {props.sectionData}
        </div>
    </React.Fragment>
);

export default ProjectSection;