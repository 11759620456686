import { JAVA } from './languages.js';

const JAVA_PROJECTS = [
    {
        id: 1,
        title: "Java Multithread",
        modalTitle: "javaMultiThread",
        language: JAVA,
        imgSource: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/javamultithread.png",
        info: "A multi-threaded program in Java. Simulates students rushing into a class room and trying to occupy the seats inside the class room. There is a student thread and instructor thread. They share the classroom object in which controls their synchronization. Also takes 2 command line arguments. The first argument is the number of students you want to add. The second argument is the capacity (or number of seats) you want for the classroom. If no arguments are input, then the default of 14 students and a capacity of 10 is used. Note: GitHub repo may be on private. Message me if you want to see the code.",
        gif: "https://raw.githubusercontent.com/afranco07/JavaMultiThread/master/multiThreadgif.gif?token=AEHDLKXQSZTPNMCM4FWBL227HFGKA",
        github: "https://github.com/afranco07/JavaMultithreadSemaphore",
        website: null
    }, {
        id: 0,
        title: "Animal Simulation",
        modalTitle: "AnimalSim",
        language: JAVA,
        imgSource: "https://raw.githubusercontent.com/afranco07/gifImageStorage/master/animalsimulation.png",
        info: "This small app simulates animals wandering on a grid.",
        gif: "https://raw.githubusercontent.com/afranco07/animalSimulation/master/animalSimDemo.gif",
        github: "https://github.com/afranco07/animalSimulation",
        website: null
    }
];

export default JAVA_PROJECTS;