import React from 'react';

const Paper = (props) => {
    let titleRender = null;
    if (!props.title) {
        titleRender = null;
    } else if (!props.subtitle) {
        titleRender = <React.Fragment><h6><strong>{props.title}</strong></h6><br /></React.Fragment>;
    } else {
        titleRender = <React.Fragment><h6><strong>{props.title},</strong> {props.subtitle}</h6><br /></React.Fragment>;
    }

    return (
        <div className="row">
            <div className="col s12">
                <div className="card hoverable">
                    <div className="card-content black-text z-depth-1">
                        <span className="card-title"><strong>{props.companyName}</strong></span>
                        {titleRender}
                        {props.bulletPoints}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Paper;
