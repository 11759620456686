import React, { Component } from 'react';
import NavBar from './navbar/navbar.js';
import './App.css';
import Home from './home/home.js';
import Projects from './projects/projects.js';
import AboutMe from './aboutme/aboutme.js';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Footer from './footer/footer.js';
import 'materialize-css/dist/css/materialize.min.css';
import ProfilePicture from './profilepic/profilepic.js';

class App extends Component {
  
  render() {
    return (
      <div className="App">
        <Router>
          <React.Fragment>
            <NavBar />
            <div id="navbar-padding-style"></div>
            <ProfilePicture />
            <div id="jumboHeader"></div>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/projects" exact component={Projects} />
                <Route path="/about" exact component={AboutMe} />
              </Switch>
              <Footer/>
          </React.Fragment>
        </Router>
      </div>
    );
  }
}

export default App;
