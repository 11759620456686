import React from "react";

const LANGUAGESTECH = [
  {
    id: 3,
    companyName: "Programming Languages",
    title: null,
    subtitle: null,
    bulletPoints: [
      <ul key={0}>
        <li>Go</li>
        <li>Java</li>
        <li>JavaScript</li>
        <li>HTML/CSS</li>
        <li>Python</li>
        <li>C++/C#</li>
        <li>SQL</li>
        <li>Bash</li>
        <li>Swift</li>
        <li>SQL &amp; NoSQL</li>
      </ul>,
    ],
  },
  {
    id: 2,
    companyName: "Frameworks/Libraries",
    title: null,
    subtitle: null,
    bulletPoints: [
      <ul key={0}>
        <li>Kubernetes &amp; Helm</li>
        <li>ReactJS &amp; Redux</li>
        <li>NodeJS &amp; NPM</li>
        <li>ExpressJS</li>
        <li>Django</li>
        <li>Flask</li>
        <li>Sequelize ORM</li>
        <li>WebPack</li>
        <li>ElectronJS</li>
      </ul>,
    ],
  },
  {
    id: 1,
    companyName: "Certifications",
    title: null,
    subtitle: null,
    bulletPoints: [
      <ul key={0}>
        <li>AWS Solutions Architect Associate</li>
        <li>AWS Certified Cloud Practioner</li>
      </ul>,
    ],
  },
  {
    id: 0,
    companyName: "Applications",
    title: null,
    subtitle: null,
    bulletPoints: [
      <ul key={0}>
        <li>AWS Cloud</li>
        <li>Ubuntu Linux</li>
        <li>Git/GitHub</li>
        <li>Docker</li>
        <li>Postman</li>
        <li>Adobe Photoshop</li>
        <li>Splunk</li>
        <li>Microsoft Visual Studio</li>
        <li>Intellij IDEA &amp; Pycharm</li>
        <li>Unity Game Engine</li>
        <li>Xcode</li>
        <li>Eclipse</li>
      </ul>,
    ],
  },
];

export default LANGUAGESTECH;
