import React from "react";

const EXPERIENCE = [
  /*{
    id: 7,
    companyName: "Fox",
    title: "Senior Software Enginer",
    subtitle: "Backend",
    bulletPoints: [
      <React.Fragment key={0}>
        <p>Backend Go Engineer</p>
        <br />
      </React.Fragment>,
    ],
  },*/
  {
    id: 6,
    companyName: "TheGuarantors",
    title: "Senior Software Enginer",
    subtitle: "Backend",
    bulletPoints: [
      <React.Fragment key={0}>
        <p>
          Designed and implemented a comprehensive test suite using GitHub
          Actions, covering functional, regression, and edge case scenarios,
          resulting in a significant reduction of bugs and enhancing overall
          release confidence
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={1}>
        <p>
          Diligently maintained a complex legacy system, proactively
          troubleshooting issues, applying critical updates, and refactoring
          code when necessary, ensuring the system's continued reliability and
          functionality, and extending its operational life while minimizing
          technical debt
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={2}>
        <p>
          Mentored and provided guidance to new engineers, fostering their
          professional growth and proficiency in software development practices,
          resulting in accelerated onboarding and improved team productivity
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={3}>
        <p>
          Successfully integrated various AWS services into the application
          architecture, including but not limited to EKS, AppConfig, S3, and
          RDS, to optimize scalability, reliability, and performance, resulting
          in a more robust and cost-efficient system
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={4}>
        <p>
          Successfully led the conversion of a JavaScript codebase to
          TypeScript, enhancing code maintainability and improving overall
          development efficiency, resulting in a more robust and scalable
          application
        </p>
        <br />
      </React.Fragment>,
    ],
  },
  {
    id: 5,
    companyName: "CircleCI",
    title: "Software Enginer",
    subtitle: "Backend",
    bulletPoints: [
      <React.Fragment key={0}>
        <p>
          Developed and maintained container-runner, the Kubernetes solution for
          using containers on Self-hosted Runners which allowed for using Docker
          images for building and testing
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={1}>
        <p>
          Developed and maintained the Self-hosted Runner client, the solution
          for using your own infrastructure for running jobs which allowed for a
          wider variety of architectures for building and testing and additional
          control over the build environment
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={2}>
        <p>
          Developed and maintained the Runner UI installation wizard and
          Inventory using React that allowed customers a faster and easier way
          to view and create Runner related resources from the browser
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={3}>
        <p>
          Troubleshooted customer issues across across our distributed systems
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={4}>
        <p>
          Implemented monitoring for our services using DataDog UX synthetics
          testing and Honeycomb observability to detect potential issues quickly
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={5}>
        <p>
          Verified Runner services and features for every release of our Server
          product
        </p>
      </React.Fragment>,
    ],
  },
  {
    id: 4,
    companyName: "Ghostery",
    title: "Software Enginer",
    subtitle: "Backend",
    bulletPoints: [
      <React.Fragment key={0}>
        <p>
          Built web application that searches for all data we have on a user for
          easy export and deleting capabilities to comply with GDPR regulations
          (React + Go)
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={1}>
        <p>
          Built CI/CD process for the Ghostery Midnight product that runs
          Javascript and Go code linting and tests, builds Windows and Mac
          versions, uploads to AWS S3 buckets, and posts the builds to their
          appropriate Jira tickets for testing
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={2}>
        <p>
          Created dashboard to monitor VPN usage and statistics on the Ghostery
          Midnight product using React and Go
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={3}>
        <p>
          Develop and maintain various back end solutions and RESTful APIs in Go
          (user account data, Stripe integrations, email integrations)
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={4}>
        <p>
          Maintain and implement features for Ghostery Midnight desktop
          application (React + Electron + Go)
        </p>
      </React.Fragment>,
    ],
  },
  {
    id: 3,
    companyName: "Abstract",
    title: "Software Enginer",
    subtitle: "Desktop Team",
    bulletPoints: [
      <React.Fragment key={0}>
        <p>
          Ported existing Javascript code to Go to improve overall application
          performance
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={1}>
        <p>
          Maintained and improved Go command line tool that communicates between
          the desktop and git repositories
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={2}>
        <p>
          Developed a new customer support API that uses Ruby on Rails to pull
          relevant customer data into Zendesk tickets and authenticates the user
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={3}>
        <p>
          Troubleshooted and implemented fixes for various customer issues in
          React, Go, and Ruby on Rails code
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={3}>
        <p>Created unit tests for newly implemented features</p>
      </React.Fragment>,
    ],
  },
  {
    id: 3,
    companyName: "Cox Automotive - Dealertrack",
    title: "Software Enginer",
    subtitle: "Contractor",
    bulletPoints: [
      <React.Fragment key={0}>
        <p>
          Developed a restful API using the Django Rest Framework that captures
          data sent between Dealertrack and multiple businesses and stores it in
          the appropriate ECS bucket and retrieves data based on request headers
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={1}>
        <p>
          Created unit tests for 100% code coverage that run in the CI/CD
          pipeline for the API
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={2}>
        <p>
          Developed a ReactJS frontend that interacts with the restful API so
          that non-technical people can retrieve data stored in the ECS buckets
        </p>
      </React.Fragment>,
    ],
  },
  {
    id: 2,
    companyName: "CUNY Tech Prep, NYC Tech Talent Pipeline",
    title: "Full Stack Developer",
    subtitle: null,
    bulletPoints: [
      <React.Fragment key={0}>
        <p>
          Prepare for a software engineering career via a competitive, yearlong
          training program that blends a full stack JavaScript boot camp with
          professional development coaching
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={1}>
        <p>
          Learn in-demand technologies like React and Node + Express as well as
          tools &amp; processes for design, implementation, testing, and
          deployment like agile &amp; Scrum with Trello, TDD, CI/CD, etc.
        </p>
      </React.Fragment>,
    ],
  },
  {
    id: 1,
    companyName: "Cox Automotive - Dealertrack",
    title: "Software Enginer",
    subtitle: "Intern",
    bulletPoints: [
      <React.Fragment key={0}>
        <p>
          Designed and developed a Splunk dashboard using custom XML and queries
          to parse logs from dealership, internal applications, and catch errors
          before they reach end-users
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={1}>
        <p>
          Built a script in IBM Datapower with JavaScript to normalize and
          aggregate data on potential car buyers that converted XML dealership
          feeds into JSON mappings for improved searchability and error
          detection
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={2}>
        <p>
          Followed Scrum best practices as part of an eight-person development
          team, participating in daily standups, backlog grooming, and
          retrospectives
        </p>
      </React.Fragment>,
    ],
  },
  {
    id: 0,
    companyName: "Accumedic Computer Systems Inc.",
    title: "Web Forms Programmer",
    subtitle: null,
    bulletPoints: [
      <React.Fragment key={0}>
        <p>
          Migrated hundreds of paper medical forms from clients into Accumedic’s
          EMR system, performed manual tests to ensure field accuracy and
          display format, and updated forms based on customer feedback
        </p>
      </React.Fragment>,
    ],
  },
  {
    id: -1,
    companyName: "York College, City University of New York",
    title: "Robotics Intern",
    subtitle: null,
    bulletPoints: [
      <React.Fragment key={0}>
        <p>Created a hexacopter with the instruction of a mentor</p>
        <br />
      </React.Fragment>,
      <React.Fragment key={1}>
        <p>
          Learned how to create 3D graphics using computer software (TinkerCad)
        </p>
        <br />
      </React.Fragment>,
      <React.Fragment key={2}>
        <p>Designed my own objects using a 3D printer and TinkerCad Software</p>
        <br />
      </React.Fragment>,
      <React.Fragment key={3}>
        <p>Tested and debugged the hardware using the Arduino software</p>
        <br />
      </React.Fragment>,
      <React.Fragment key={4}>
        <p>
          Programmed the MultiWii circuit board to use all of the added
          components
        </p>
      </React.Fragment>,
    ],
  },
];

export default EXPERIENCE;
